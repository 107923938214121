/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import NowUiKit from "./plugins/now-ui-kit";
import VueCookies from "vue-cookies";
import CookieBanner from "./components/Cookie/Banner.vue"
import VueObserveVisibility from "vue-observe-visibility";
import VueMeta from "vue-meta";
import * as VueGoogleMaps from "vue2-google-maps";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;
Vue.prototype.cdnEndpoint = process.env.VUE_APP_CDNENDPOINT;

Vue.use(VueObserveVisibility);
Vue.use(VueMeta)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(NowUiKit);
Vue.use(VueCookies);
Vue.use(CookieBanner);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: "places, drawing, visualization",
  },
  installComponents: true
})

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");