<template>
    <footer class="footer">
      <div class="row">
          <img class="brandlogo" :src="this.cdnEndpoint + '/img/brand/drremo_logo_and_name.png'" alt="Dr. Remo"/>
        </div>
        <div class="row">
          <div class="col-md-4 ml-auto ">
            <div class="link-wrapper">
              <h4>Quick Links</h4>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/findwellness">Find Wellness</a></li>
                <li><a href="/contactus">Contact Us</a></li>
                <li><a href="/aboutus">About Us</a></li>
                <li><a href="/pricing">Pricing</a></li>
                <li><a href="/blog">Blog</a></li>
                <li><a href="/helpcenter">Help Center</a></li>
                <li><a href="/faq">FAQ</a></li>
                <li><a href="/sitemap.xml">Site map</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 ml-auto ">
            <div class="link-wrapper">
              <h4>Legal Links</h4>
              <ul>
                <li><a href="/terms">Terms and Conditions</a></li>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/cookie">Cookie Policy</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 ml-auto ">
            <div class="social-wrapper">
              <h4>Our social media</h4>

              <div class="button-wrapper">
                <!-- Instagram -->
                <a name="Instagram" href="https://www.instagram.com/dr.remo_sa/" target="_blank">
                  <n-button class="btn-instagram" name="Instagram account" social icon round>
                    <i class="fab fa-instagram"></i>
                  </n-button>
                </a>

                <!-- Facebook -->
                <a name="Facebook" href="https://www.facebook.com/Dr-Remo_SA-277848954029030" target="_blank">
                  <n-button class="btn-facebook" name="Facebook account" social icon round>
                    <i class="fab fa-facebook"> </i>
                  </n-button>
                </a>

                <!-- Youtube -->
                <a name="Youtube" href="https://www.youtube.com/channel/UCZHq_AtYeE2vJB9m-_9uXRw" target="_blank">
                  <n-button class="btn-youtube" name="Youtube account" social icon round>
                    <i class="fab fa-youtube"> </i>
                  </n-button>
                </a>

                <!-- Youtube -->
                <a name="Youtube" href="https://twitter.com/DrRemo_SA" target="_blank">
                  <n-button class="btn-twitter" name="Twitter account" social icon round>
                    <i class="fab fa-twitter"> </i>
                  </n-button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="copyright">
            &copy; {{ year }} DrRemo - Designed by Apace Systems
          </div>
        </div>
    </footer>
</template>

<script>
  import { Button } from "@/components";

  export default {
    components: {
      [Button.name]: Button
    },
    data() {
      return {
        year: new Date().getFullYear()
      };
    }
  };
</script>