<template>
    <modal class="gaining-practitioners-popup" headerClasses="justify-content-center" :show.sync="showModal" :showClose="false">
        <h3 slot="header" class="title">We are the new kid on the block</h3>
        <div class="container text-center">
            <div class="row">
                <div class="avatar col-md-12 text-center pb-2">
                    <i class="far fa-smile-beam"></i>
                </div>
            </div>

            We are currently building our practitioner database. If you are unable to find a practitioner close by, please be patient, we are working hard to be in your area soon.
        </div>
        <template slot="footer">
            <n-button type="neutral" @click="gotit">Close</n-button>
            <n-button type="primary" @click="gotit">Got it</n-button>
        </template>
    </modal>
</template>
<script>
    import Vue from 'vue';
    import { Modal } from '@/components';
    import { Button } from '@/components';

    export default {
        name: 'gaining-practitioners-popup',
        components: {
            Modal,
            [Button.name]: Button
        },
        data() {
            return {
                showModal: true,
                cookieModel: {
                    name: "drremo_gaining_practitioners",
                    generateObject() {
                        return {
                            dateViewed: new Date()
                        };
                    }
                }
            };
        },
        methods: {
            initialize() {
                if(this.checkIfCookieExists()) {
                    this.showModal = false;
                }
            },
            checkIfCookieExists() {
                return Vue.$cookies.isKey(this.cookieModel.name);
            },
            gotit() {
                if(!this.checkIfCookieExists()) {
                    Vue.$cookies.set(this.cookieModel.name, this.cookieModel.generateObject(), Infinity);
                    this.initialize();
                }
            }
        },
        mounted() {
            this.initialize();
        }
    };
</script>