import { Parallax, LoadingIndicator } from '../components';
/**
 * You can register global components here
 */

const globalComponents = {
  install(Vue) {
    Vue.component(Parallax.name, Parallax);
    Vue.component(LoadingIndicator.name, LoadingIndicator);
  }
};

export default globalComponents;
