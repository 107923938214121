<template>
  <fade-transition>
    <div class="loading-indicator">
        <i class="fa fa-spinner"></i>
    </div>
  </fade-transition>
</template>
<script>
    import { FadeTransition } from 'vue2-transitions';

    export default {
        name: 'loading-indicator',
        components: {
            FadeTransition
        }
    };
</script>
