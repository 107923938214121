<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="true"
    :color-on-scroll="60"
    menu-classes="ml-auto"
    class="header"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
          <img class="brandlogo" :src="this.cdnEndpoint + '/img/brand/drremo_full_logo.png'" alt="Dr. Remo"/>
      </router-link>      
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a class="nav-link" href="/findwellness"><p>Find Wellness</p></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/aboutus"><p>About Us</p></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/contactus"><p>Contact Us</p></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/blog"><p>Blog</p></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/helpcenter"><p>Help Center</p></a>
      </li>
      <li class="nav-item">
        <div class="nav-link btn btn-info" @click="signin"><i class="fas fa-sign-in-alt"></i>&nbsp;<p>SIGN IN</p></div>
      </li>
      <li class="nav-item">
        <div class="nav-link btn btn-neutral" @click="signup"><i class="fas fa-user-plus"></i>&nbsp;<p>SIGN UP</p></div>
      </li>
    </template>
  </navbar>
</template>

<script>
import {  Navbar } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    [Popover.name]: Popover
  },
  methods: {
    signin() {
      window.location.href = process.env.VUE_APP_SIGNEDINENDPOINT;
    },
    signup() {
      window.location.href = process.env.VUE_APP_SIGNEDINENDPOINT;;
    }
  }
};
</script>

<style scoped></style>
