<template>
    <div class="cookie-popup fixed-bottom" :class="[{ 'd-none': !showBanner }]">
        <card class="col-lg-4 col-md-8 col-sm-12 text-center m-0 rounded-0">
            <div class="row">
                <div class="avatar col-md-12 text-center"><i class="fas fa-cookie"></i></div>
            </div>
            <div class="row text-center">
                <h4 class="card-title col-md-12 font-weight-bold">Your Privacy is important to us</h4>
                <div class="card-text col-md-12">By clicking “Accept all cookies”, you agree DrRemo can store cookies on your device and disclose information in accordance with our <a href="/#/cookie">Cookie Policy</a>.</div>
            </div>
            <br />
            <div class="row">
                <n-button type="primary" class="col-md-12" @click="acceptAllCookies">Accept All Cookies</n-button>
                <n-button type="neutral" class="col-md-12" @click="toggleModal(true)">Customize settings</n-button>
            </div>
        </card>
        
        <modal :show.sync="showModal" headerClasses="justify-content-center" :showClose="false">
            <h3 slot="header" class="title title-up">Customize Cookie Preferences</h3>
            <div class="container">
                <div class="row text-center description">
                    When you visit DrRemo, it may store or retrieve information on your browser, mostly in the form of cookies. 
                    This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. 
                    The information does not usually directly identify you, but it can give you a more personalized web experience. 
                    Because we respect your right to privacy, you can choose not to allow some types of cookies. 
                    Click on the different category headings to find out more and manage your preferences. 
                    Please note, blocking some types of cookies may impact your experience of the site and the services we are able to offer.
                </div>
                <br />

                <div class="row">
                    <div class="col-md-9">
                        Strictly Necessary&nbsp;<i class="fa fa-info-circle align-self-center" role="button" @click="extraInfoModel.strictlyNecessary = !extraInfoModel.strictlyNecessary"></i>

                        <div class="font-italic m-2 text-primary information" :class="[{ 'd-none': !extraInfoModel.strictlyNecessary }]">
                            These cookies are necessary for our website to function properly and cannot be switched off in our systems. 
                            They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms or where they’re essential to provide you with a service you have requested. 
                            You cannot opt-out of these cookies. You can set your browser to block or alert you about these cookies, but if you do, some parts of the site will not then work. 
                            These cookies do not store any personally identifiable information.
                        </div>
                    </div>
                    <div class="col-md-3">
                        <n-switch v-model="cookieModel.strictlyNecessary" on-text="ON" off-text="OFF"></n-switch>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        Performance Cookies&nbsp;<i class="fa fa-info-circle align-self-center" role="button" @click="extraInfoModel.performance = !extraInfoModel.performance"></i>

                        <div class="font-italic m-2 text-primary information" :class="[{ 'd-none': !extraInfoModel.performance }]">
                            These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. 
                            They help us to know which pages are the most and least popular and see how visitors move around the site, which helps us optimize your experience. 
                            All information these cookies collect is aggregated and therefore anonymous. 
                            If you do not allow these cookies we will not be able to use your data in this way.
                        </div>
                    </div>
                    <div class="col-md-3">
                        <n-switch v-model="cookieModel.performance" on-text="ON" off-text="OFF"></n-switch>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        Functional Cookies&nbsp;<i class="fa fa-info-circle align-self-center" role="button" @click="extraInfoModel.functional = !extraInfoModel.functional"></i>

                        <div class="font-italic m-2 text-primary information" :class="[{ 'd-none': !extraInfoModel.functional }]">
                            These cookies enable the website to provide enhanced functionality and personalization. 
                            They may be set by us or by third party providers whose services we have added to our pages. 
                            If you do not allow these cookies then some or all of these services may not function properly.
                        </div>
                    </div>
                    <div class="col-md-3">
                        <n-switch v-model="cookieModel.functional" on-text="ON" off-text="OFF"></n-switch>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        Targeting Cookies&nbsp;<i class="fa fa-info-circle align-self-center" role="button" @click="extraInfoModel.targeting = !extraInfoModel.targeting"></i>

                        <div class="font-italic m-2 text-primary information" :class="[{ 'd-none': !extraInfoModel.targeting }]">
                            These cookies may be set through our site by our advertising partners. 
                            They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. 
                            They do not store directly personal information but are based on uniquely identifying your browser and internet device. 
                            If you do not allow these cookies, you will experience less targeted advertising.
                        </div>
                    </div>
                    <div class="col-md-3">
                        <n-switch v-model="cookieModel.targeting" on-text="ON" off-text="OFF"></n-switch>
                    </div>
                </div>
            </div>
            <template slot="footer">
                <n-button type="neutral" @click="toggleModal(false)">Close</n-button>
                <n-button type="primary" @click="acceptCookies">Accept</n-button>
            </template>
        </modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Modal } from '@/components';
    import { Card } from '@/components';
    import { Button } from '@/components';
    import { Switch } from '@/components';

    export default {
        name: 'cookie-banner',
        components: {
            Modal,
            Card,
            [Button.name]: Button,
            [Switch.name]: Switch
        },
        data() {
            return {
                showModal: false,
                showBanner: true,
                extraInfoModel: {
                    strictlyNecessary: false,
                    performance: false,
                    functional: false,
                    targeting: false,
                },
                cookieModel: {
                    name: "drremo_privacy_settings",
                    strictlyNecessary: true,
                    performance: false,
                    functional: false,
                    targeting: false,

                    setCookieSettings(cookie) {
                        this.strictlyNecessary = cookie.selectedOptions.strictlyNecessary;
                        this.performance = cookie.selectedOptions.performance;
                        this.functional = cookie.selectedOptions.functional;
                        this.targeting = cookie.selectedOptions.targeting;
                    },
                    generateObject() {
                        return {
                            dateAccepted: new Date(),
                            selectedOptions: {
                                strictlyNecessary: this.strictlyNecessary,
                                performance: this.performance,
                                functional: this.functional,
                                targeting: this.targeting
                            }
                        };
                    }
                }
            };
        },
        methods: {
            toggleModal(val) {
                this.showModal = val;
            },
            checkIfCookieExists() {
                return Vue.$cookies.isKey(this.cookieModel.name);
            },
            acceptAllCookies() {
                this.cookieModel.strictlyNecessary = true;
                this.cookieModel.performance = true;
                this.cookieModel.functional = true;
                this.cookieModel.targeting = true;

                this.acceptCookies();
            },
            acceptCookies() {
                if(!this.checkIfCookieExists()) {
                    Vue.$cookies.set(this.cookieModel.name, this.cookieModel.generateObject(), Infinity);
                    this.applyUserPreferences();
                }
            },

            applyUserPreferences() {
                this.showBanner = !this.checkIfCookieExists();
                this.applyCurrentPreferences();
                this.removeThirdpartyScripts();
            },
            applyCurrentPreferences() {
                if(this.checkIfCookieExists()) {
                    this.cookieModel.setCookieSettings(Vue.$cookies.get(this.cookieModel.name));
                }
            },
            removeThirdpartyScripts() {
                if(this.checkIfCookieExists()) {
                    var scripts = document.querySelectorAll("script");
                    scripts.forEach(script => {
                        const category = script.getAttribute("cookie-consent");
                        const domain = script.getAttribute("src");

                        if(this.cookieModel[category] !== null && this.cookieModel[category] !== undefined) {
                            if(!this.cookieModel[category]) {
                                script.remove();
                            }
                        } else if(this.isValidUrl(domain)) {
                            script.remove();
                        }
                    });
                }
            },
            isValidUrl(url) {
                var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                return !!pattern.test(url);
            }
        },
        mounted() {
            this.applyUserPreferences();
        }
    };
</script>