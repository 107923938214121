import Vue from "vue";
import Router from "vue-router";

import Header from "./layout/MainHeader.vue";
import Footer from "./layout/MainFooter.vue";
import CookieBanner from "./components/Cookie/Banner.vue";
import GainingPractitionersPopup from "./components/Popup/GainingPractitionersPopup.vue";

Vue.use(Router);
Vue.use(require("vue-moment"));

function lazyLoad(view){
  return() => import(`@/pages/${view}.vue`)
}

const headerDefaults = {
  colorOnScroll: 100,
  transparent: true
};

export default new Router({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      components: { default: lazyLoad("Home"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/aboutus",
      name: "aboutus",
      components: { default: lazyLoad("AboutUs"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/contactus",
      name: "contactus",
      components: { default: lazyLoad("ContactUs"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll }
      }
    },
    {
      path: "/faq",
      name: "frequentlyaskedquestions",
      components: { default: lazyLoad("FrequentlyAskedQuestions"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll},
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/terms",
      name: "termsandconditions",
      components: { default: lazyLoad("TermsAndConditions"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/practitionerterms",
      name: "termsandconditionspractitioner",
      components: { default: lazyLoad("TermsAndConditions_Practitioner"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/findwellness",
      name: "findwellness",
      components: { default: lazyLoad("FindWellness"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/profile",
      name: "profile",
      components: { default: lazyLoad("Profile"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/privacy",
      name: "privacy",
      components: { default: lazyLoad("PrivacyPolicy"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/cookie",
      name: "cookie",
      components: { default: lazyLoad("CookiePolicy"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: headerDefaults.transparent, colorOnScroll: headerDefaults.colorOnScroll },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/blog",
      name: "blog",
      components: { default: lazyLoad("Blog"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: true, colorOnScroll: 100 },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/article/:articleid",
      name: "article",
      components: { default: lazyLoad("Article"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: true, colorOnScroll: 100 },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/pricing",
      name: "pricing",
      components: { default: lazyLoad("Pricing"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: true, colorOnScroll: 100 },
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/helpcenter",
      name: "helpcenter",
      components: { default: lazyLoad("HelpCenter"), mainheader: Header, mainfooter: Footer, cookiebanner: CookieBanner, gainingpractitionerspopup: GainingPractitionersPopup },
      props: {
        header: { transparent: true, colorOnScroll: 100 },
        footer: { backgroundColor: "black" }
      }
    },

    {
      path: "/PatientManual",
      name: "patient",
      components: { default: lazyLoad("PatientManual")},
    },
    {
      path: "/PracticeManual",
      name: "practice",
      components: { default: lazyLoad("PracticeManual")},
    },
    {
      path: "/PractitionerManual",
      name: "practitioner",
      components: { default: lazyLoad("PractitionerManual")},
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
